.App {
}

.App-header {
  text-align: center;
}

.App-logo {
  height: 140px;
  pointer-events: none;
  margin: 10px 0;
}

.Block-title {
  text-align: center;
  margin: 30px 0;
}

.Default-list li {
  margin: 20px 0;
}

.Block-call-to-action {
  text-align: center;
  margin: 30px 0;
}

.Block-call-to-action > * {
  margin: 0 20px !important;
}

.Course-dropdown {
  width: 100%;
  font-size: 0.875rem !important;
  margin-top: -0.875rem;
  margin-bottom: -0.875rem;
}
